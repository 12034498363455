<template>
  <df-select-dropdown
    ref="dropdown"
    :items="items"
    :clearable="clearable"
    :multiple="multiple"
    :search-bar="searchBar"
    :search-placeholder="searchPlaceholder"
    :value="value"
    @apply-filter="applyMultipleFilter"
    @click="applySingleFilter"
  >
    <template #default="slotProps">
      <div class="df-flex-sm df-flex-col df-select">
        <h3 v-if="label">{{ label }}<span v-if="hasRequiredRule">*</span></h3>
        <div v-on="slotProps.on">
          <v-text-field
            data-id="select-input"
            :value="componentText"
            dense
            flat
            hide-spin-buttons
            outlined
            readonly
            solo
            :background-color="backgroundColor"
            :disabled="disabled"
            :loading="loading"
            :placeholder="placeholder"
            :rules="rules"
          >
            <template #append>
              <font-awesome-icon class="icon" icon="chevron-down" />
            </template>
          </v-text-field>
        </div>
      </div>
    </template>
  </df-select-dropdown>
</template>

<script>
import DfSelectDropdown from '@/lib/components/Dropdown/DfSelectDropdown.vue'

export default {
  name: 'DfSelect',

  components: {
    DfSelectDropdown,
  },

  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    items: {
      required: true,
      type: Array,
      validator(value) {
        return value.every((item) => {
          return typeof item.name === 'string' && typeof item.value === 'string'
        })
      },
    },
    label: {
      default: '',
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: '',
      type: String,
    },
    rules: {
      default: () => [],
      type: Array,
    },
    searchBar: {
      default: false,
      type: Boolean,
    },
    searchPlaceholder: {
      default: '',
      type: String,
    },
    value: {
      required: true,
      type: [Array, Object],
    },
  },

  data() {
    return {
      componentText: '',
      componentValue: this.multiple ? [] : this.value,
      hasRequiredRule: false,
    }
  },

  created() {
    this.rules.some((rule) => {
      if (rule.name === 'required') this.hasRequiredRule = true
    })
  },

  watch: {
    value: {
      handler(newValue) {
        const itemsText = []
        if (this.multiple) {
          newValue.forEach((item) => {
            itemsText.push(item.name)
          })
          this.componentText = itemsText.join(', ')
          return
        }
        this.componentText = newValue.name
      },
    },
  },

  computed: {
    backgroundColor() {
      return this.disabled ? '#e6e9e6' : '#f8f9f8'
    },
  },

  methods: {
    applyMultipleFilter(value) {
      this.componentValue = this.items.filter((item) =>
        value.includes(String(item.value))
      )
      this.$emit('input', [...this.componentValue])
      this.$emit('apply-filter')
    },
    applySingleFilter(value) {
      this.componentValue = value
      this.$emit('input', value)
      this.$emit('click', value)
    },
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    cleanFilter() {
      this.$refs.dropdown.cleanFilter()
      this.componentValue = []
      this.componentText = []
    },
  },
}
</script>

<style lang="scss" scoped>
.df-select {
  h3 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    span {
      color: #f23528;
    }
  }
  .icon {
    color: #5b6459;
  }
}
// placeholder
::v-deep .v-text-field__slot input::placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #5b6459;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  top: 0px !important;
}
::v-deep .v-input__slot {
  padding: 12px 16px !important;
  margin-bottom: 0px !important;
  cursor: pointer !important;
}
::v-deep .v-input--is-disabled .v-input__slot {
  cursor: default !important;
}
::v-deep .v-input--is-focused fieldset {
  border-color: #39af49 !important;
}
// typed text
::v-deep .v-text-field__slot input {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0px;
  cursor: pointer !important;
}
::v-deep .v-input--is-disabled input {
  cursor: default !important;
}
// error messages
::v-deep .v-text-field__details {
  padding: 0px !important;
  margin: 2px 0px 0px 0px !important;

  .v-messages__message {
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}
</style>
